export const AREAOFEXPERTISE_SUCCESS = "AREAOFEXPERTISE_SUCCESS";
export const AREAOFEXPERTISE_FAIL = "AREAOFEXPERTISE_FAIL";
export const AREAOFEXPERTISE_REQUEST = "AREAOFEXPERTISE_REQUEST";
export const AREAOFEXPERTISE_CREATE_REQUEST = "AREAOFEXPERTISE_CREATE_REQUEST";
export const AREAOFEXPERTISE_CREATE_SUCCESS = "AREAOFEXPERTISE_CREATE_SUCCESS";
export const AREAOFEXPERTISE_CREATE_FAIL = "AREAOFEXPERTISE_CREATE_FAIL";
export const AREAOFEXPERTISE_UPDATE_REQUEST = "AREAOFEXPERTISE_UPDATE_REQUEST";
export const AREAOFEXPERTISE_UPDATE_SUCCESS = "AREAOFEXPERTISE_UPDATE_SUCCESS";
export const AREAOFEXPERTISE_UPDATE_FAIL = "AREAOFEXPERTISE_UPDATE_FAIL";
export const AREAOFEXPERTISE_UPDATE_EMPTY = "AREAOFEXPERTISE_UPDATE_EMPTY";
export const AREAOFEXPERTISE_DELETE_REQUEST = "AREAOFEXPERTISE_DELETE_REQUEST";
export const AREAOFEXPERTISE_DELETE_EMPTY = "AREAOFEXPERTISE_DELETE_EMPTY";
export const AREAOFEXPERTISE_DELETE_SUCCESS = "AREAOFEXPERTISE_DELETE_SUCCESS";
export const AREAOFEXPERTISE_DELETE_FAIL = "AREAOFEXPERTISE_DELETE_FAIL";

export const SINGLE_AREAOFEXPERTISE_SUCCESS = "SINGLE_AREAOFEXPERTISE_SUCCESS";
export const SINGLE_AREAOFEXPERTISE_FAIL = "SINGLE_AREAOFEXPERTISE_FAIL";
export const SINGLE_AREAOFEXPERTISE_REQUEST = "SINGLE_AREAOFEXPERTISE_REQUEST";
