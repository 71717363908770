// import Home from "../pages/home";
// import { Routes, Route, useLocation } from "react-router-dom";
// import People from "../pages/people";
// import ProtectedRoutes from "../routes/protectedRoute";
// import Dashbord from "../pages/admin/dashbord";
// import Thefirmadmin from "../pages/admin/thefirm";
// import Areaofexpertise from "../pages/admin/areaofExpertise";
// import Login from "../pages/login";
// import ThefirmForm from "../pages/admin/thefirmForm";
// import ThefirmUpdate from "../pages/admin/thefirmUpdate";
// import AreaOfExperseForm from "../pages/admin/areaofExpertiseForm";
// import AreaofexpertiseEdit from "../pages/admin/areaofExpertiseEdit";
// import IndustrySector from "../pages/admin/industrysector";
// import IndustrySectorCreate from "../pages/admin/industrysectorForm";
// import IndustrysectorEdit from "../pages/admin/industrysectorEdit";
// import Partners from "../pages/admin/partners";
// import PartnersCreate from "../pages/admin/partnersForm";
// import PartnersEdit from "../pages/admin/partnersEdit";
// import Associates from "../pages/admin/associates";
// import AssociatesCreate from "../pages/admin/associatesForm";
// import AssociatesEdit from "../pages/admin/associatesEdit";
// import InformationtechnologyCreate from "../pages/admin/informationtechnologyForm";
// import Informationtechnology from "../pages/admin/informationtechnology";
// import InformationtechnologyEdit from "../pages/admin/informationtechnologyEdit";
// import Canvas from "../pages/canvas";
// import PrivacyAndSecurity from "../pages/privacyAndSecurity";
// import DetailKnowMore from "../pages/detailKnowMore";
// import ReachOutAndConnect from "../pages/ReachoutConnect";
// import SeniourAssociates from "../pages/admin/seniourAssociates";
// import SeniourAssociteCreate from "../pages/admin/seniourAssociatesForm";
// import SeniourAssocitesEdit from "../pages/admin/seniourAssocitesEdit";
// import AssociatePartners from "../pages/admin/associatePartners";
// import AssociatePartnerCreate from "../pages/admin/associtePartnersForm";
// import AssociatePartnersEdit from "../pages/admin/associtePartnersEdit";

// const AnimatedRoutes = () => {
//   const location = useLocation();
//   return (
//     <Routes location={location} key={location.pathname}>
//       <Route element={<ProtectedRoutes />}>
//         <Route exact={true} path="/admin" element={<Dashbord />} />
//         <Route exact={true} path="/admin/thefirm" element={<Thefirmadmin />} />
//         <Route
//           exact={true}
//           path="/admin/areaofexpertise"
//           element={<Areaofexpertise />}
//         />
//         <Route
//           exact={true}
//           path="/admin/areaofexpertise/create"
//           element={<AreaOfExperseForm />}
//         />
//         <Route
//           exact={true}
//           path="/admin/areaofexpertise/edit/:id"
//           element={<AreaofexpertiseEdit />}
//         />
//         <Route
//           exact={true}
//           path="/admin/industrysector"
//           element={<IndustrySector />}
//         />
//         <Route
//           exact={true}
//           path="/admin/industrysector/create"
//           element={<IndustrySectorCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/industrysetor/edit/:id"
//           element={<IndustrysectorEdit />}
//         />
//         <Route
//           exact={true}
//           path="/admin/thefirm/create"
//           element={<ThefirmForm />}
//         />
//         <Route
//           exact={true}
//           path="/admin/thefirm/edit/:id"
//           element={<ThefirmUpdate />}
//         />
//         <Route exact={true} path="/admin/partners" element={<Partners />} />
//         <Route
//           exact={true}
//           path="/admin/partners/create"
//           element={<PartnersCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/partners/edit/:id"
//           element={<PartnersEdit />}
//         />
//         <Route exact={true} path="/admin/associates" element={<Associates />} />
//         <Route
//           exact={true}
//           path="/admin/associates/create"
//           element={<AssociatesCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/associates/edit/:id"
//           element={<AssociatesEdit />}
//         />

//         <Route
//           exact={true}
//           path="/admin/seniourAssociate"
//           element={<SeniourAssociates />}
//         />
//         <Route
//           exact={true}
//           path="/admin/seniourAssociate/create"
//           element={<SeniourAssociteCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/seniourAssociate/edit/:id"
//           element={<SeniourAssocitesEdit />}
//         />
//         {/* associatePartner */}

//         <Route
//           exact={true}
//           path="/admin/associatePartner"
//           element={<AssociatePartners />}
//         />
//         <Route
//           exact={true}
//           path="/admin/associatePartner/create"
//           element={<AssociatePartnerCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/associatePartner/edit/:id"
//           element={<AssociatePartnersEdit />}
//         />

//         <Route
//           exact={true}
//           path="/admin/informationtechnology"
//           element={<Informationtechnology />}
//         />
//         <Route
//           exact={true}
//           path="/admin/informationtechnology/create"
//           element={<InformationtechnologyCreate />}
//         />
//         <Route
//           exact={true}
//           path="/admin/informationtechnology/edit/:id"
//           element={<InformationtechnologyEdit />}
//         />
//       </Route>
//       <Route exact={true} path="/" element={<Canvas />} />
//       <Route exact={true} path="/home" element={<Home />} />
//       <Route
//         exact={true}
//         path="privacy-and-security"
//         element={<PrivacyAndSecurity />}
//       />
//       <Route
//         exact={true}
//         path="reach-out-and-connect"
//         element={<ReachOutAndConnect />}
//       />
//       <Route exact={true} path="/people" element={<People />} />
//       <Route exact={true} path="/know-more/:id" element={<DetailKnowMore />} />
//       <Route exact={true} path="/login" element={<Login />} />
//     </Routes>
//   );
// };

// export default AnimatedRoutes;

import React, { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LoadingAnimation from "../components/LoadingAnimation";

// Lazy-loaded components
const Home = lazy(() => import("../pages/home"));
const People = lazy(() => import("../pages/people"));
const ProtectedRoutes = lazy(() => import("../routes/protectedRoute"));
const Dashbord = lazy(() => import("../pages/admin/dashbord"));
const Thefirmadmin = lazy(() => import("../pages/admin/thefirm"));
const Areaofexpertise = lazy(() => import("../pages/admin/areaofExpertise"));
const Login = lazy(() => import("../pages/login"));
const ThefirmForm = lazy(() => import("../pages/admin/thefirmForm"));
const ThefirmUpdate = lazy(() => import("../pages/admin/thefirmUpdate"));
const AreaOfExperseForm = lazy(() =>
  import("../pages/admin/areaofExpertiseForm")
);
const AreaofexpertiseEdit = lazy(() =>
  import("../pages/admin/areaofExpertiseEdit")
);
const IndustrySector = lazy(() => import("../pages/admin/industrysector"));
const IndustrySectorCreate = lazy(() =>
  import("../pages/admin/industrysectorForm")
);
const IndustrysectorEdit = lazy(() =>
  import("../pages/admin/industrysectorEdit")
);
const Partners = lazy(() => import("../pages/admin/partners"));
const PartnersCreate = lazy(() => import("../pages/admin/partnersForm"));
const PartnersEdit = lazy(() => import("../pages/admin/partnersEdit"));
const Associates = lazy(() => import("../pages/admin/associates"));
const AssociatesCreate = lazy(() => import("../pages/admin/associatesForm"));
const AssociatesEdit = lazy(() => import("../pages/admin/associatesEdit"));
const InformationtechnologyCreate = lazy(() =>
  import("../pages/admin/informationtechnologyForm")
);
const Informationtechnology = lazy(() =>
  import("../pages/admin/informationtechnology")
);
const InformationtechnologyEdit = lazy(() =>
  import("../pages/admin/informationtechnologyEdit")
);
const Canvas = lazy(() => import("../pages/canvas"));
const PrivacyAndSecurity = lazy(() => import("../pages/privacyAndSecurity"));
const DetailKnowMore = lazy(() => import("../pages/detailKnowMore"));
const ReachOutAndConnect = lazy(() => import("../pages/ReachoutConnect"));
const SeniourAssociates = lazy(() =>
  import("../pages/admin/seniourAssociates")
);
const SeniourAssociteCreate = lazy(() =>
  import("../pages/admin/seniourAssociatesForm")
);
const SeniourAssocitesEdit = lazy(() =>
  import("../pages/admin/seniourAssocitesEdit")
);
const AssociatePartners = lazy(() =>
  import("../pages/admin/associatePartners")
);
const AssociatePartnerCreate = lazy(() =>
  import("../pages/admin/associtePartnersForm")
);
const AssociatePartnersEdit = lazy(() =>
  import("../pages/admin/associtePartnersEdit")
);

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Routes location={location} key={location.pathname}>
        <Route element={<ProtectedRoutes />}>
          <Route exact={true} path="/admin" element={<Dashbord />} />
          <Route
            exact={true}
            path="/admin/thefirm"
            element={<Thefirmadmin />}
          />
          <Route
            exact={true}
            path="/admin/areaofexpertise"
            element={<Areaofexpertise />}
          />
          <Route
            exact={true}
            path="/admin/areaofexpertise/create"
            element={<AreaOfExperseForm />}
          />
          <Route
            exact={true}
            path="/admin/areaofexpertise/edit/:id"
            element={<AreaofexpertiseEdit />}
          />
          <Route
            exact={true}
            path="/admin/industrysector"
            element={<IndustrySector />}
          />
          <Route
            exact={true}
            path="/admin/industrysector/create"
            element={<IndustrySectorCreate />}
          />
          <Route
            exact={true}
            path="/admin/industrysetor/edit/:id"
            element={<IndustrysectorEdit />}
          />
          <Route
            exact={true}
            path="/admin/thefirm/create"
            element={<ThefirmForm />}
          />
          <Route
            exact={true}
            path="/admin/thefirm/edit/:id"
            element={<ThefirmUpdate />}
          />
          <Route exact={true} path="/admin/partners" element={<Partners />} />
          <Route
            exact={true}
            path="/admin/partners/create"
            element={<PartnersCreate />}
          />
          <Route
            exact={true}
            path="/admin/partners/edit/:id"
            element={<PartnersEdit />}
          />
          <Route
            exact={true}
            path="/admin/associates"
            element={<Associates />}
          />
          <Route
            exact={true}
            path="/admin/associates/create"
            element={<AssociatesCreate />}
          />
          <Route
            exact={true}
            path="/admin/associates/edit/:id"
            element={<AssociatesEdit />}
          />

          <Route
            exact={true}
            path="/admin/seniourAssociate"
            element={<SeniourAssociates />}
          />
          <Route
            exact={true}
            path="/admin/seniourAssociate/create"
            element={<SeniourAssociteCreate />}
          />
          <Route
            exact={true}
            path="/admin/seniourAssociate/edit/:id"
            element={<SeniourAssocitesEdit />}
          />
          {/* associatePartner */}

          <Route
            exact={true}
            path="/admin/associatePartner"
            element={<AssociatePartners />}
          />
          <Route
            exact={true}
            path="/admin/associatePartner/create"
            element={<AssociatePartnerCreate />}
          />
          <Route
            exact={true}
            path="/admin/associatePartner/edit/:id"
            element={<AssociatePartnersEdit />}
          />

          <Route
            exact={true}
            path="/admin/informationtechnology"
            element={<Informationtechnology />}
          />
          <Route
            exact={true}
            path="/admin/informationtechnology/create"
            element={<InformationtechnologyCreate />}
          />
          <Route
            exact={true}
            path="/admin/informationtechnology/edit/:id"
            element={<InformationtechnologyEdit />}
          />
        </Route>
        <Route exact={true} path="/" element={<Canvas />} />
        <Route exact={true} path="/home" element={<Home />} />
        <Route
          exact={true}
          path="privacy-and-security"
          element={<PrivacyAndSecurity />}
        />
        <Route
          exact={true}
          path="reach-out-and-connect"
          element={<ReachOutAndConnect />}
        />
        <Route exact={true} path="/people" element={<People />} />
        <Route
          exact={true}
          path="/know-more/:id"
          element={<DetailKnowMore />}
        />
        <Route exact={true} path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  );
};

export default AnimatedRoutes;
