import "bootstrap/dist/css/bootstrap.min.css";
import { AnimatePresence } from "framer-motion";
import "admin-lte/plugins/fontawesome-free/css/all.min.css";
import "admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "admin-lte/plugins/jqvmap/jqvmap.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "admin-lte/plugins/daterangepicker/daterangepicker.css";
import "admin-lte/plugins/summernote/summernote-bs4.min.css";
import "./components/header/header.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import AnimatedRoutes from "./routes/AnimatedRoutes";
import LoadingAnimation from "./components/LoadingAnimation";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <LoadingAnimation />
        <AnimatePresence exitBeforeEnter>
          <AnimatedRoutes />
        </AnimatePresence>
      </Router>
    </Provider>
  );
};

export default App;
