import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import Brand from "./brand/Brand";

const LoadingAnimation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate some loading time (you can replace this with actual data fetching or any async operations)
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(loadingTimeout);
  }, [location, navigate]);

  return (
    <motion.div
      initial={{ opacity: 1 }} // Start with opacity 1 (fully visible)
      animate={{ opacity: isLoading ? 1 : 0 }} // Animate opacity to 0 when loading is complete
      transition={{ duration: 0.5 }} // Set the duration of the fade-out animation
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff", // Set the background color of the loading animation
        zIndex: 9999, // Set a high z-index to make sure it overlays other content
        display: isLoading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Brand
        visitLink="/home"
        style="logo_brand front-section__content__brand"
      />
    </motion.div>
  );
};

export default LoadingAnimation;
