export const INFORMATIONTECHNOLOGY_REQUEST = "INFORMATIONTECHNOLOGY_REQUEST";
export const INFORMATIONTECHNOLOGY_SUCCESS = "INFORMATIONTECHNOLOGY_SUCCESS";
export const INFORMATIONTECHNOLOGY_FAIL = "INFORMATIONTECHNOLOGY_FAIL";

export const INFORMATIONTECHNOLOGY_CREATE_REQUEST =
  "INFORMATIONTECHNOLOGY_CREATE_REQUEST";
export const INFORMATIONTECHNOLOGY_CREATE_SUCCESS =
  "INFORMATIONTECHNOLOGY_CREATE_SUCCESS";
export const INFORMATIONTECHNOLOGY_CREATE_FAIL =
  "INFORMATIONTECHNOLOGY_CREATE_FAIL";

export const INFORMATIONTECHNOLOGY_UPDATE_REQUEST =
  "INFORMATIONTECHNOLOGY_UPDATE_REQUEST";
export const INFORMATIONTECHNOLOGY_UPDATE_SUCCESS =
  "INFORMATIONTECHNOLOGY_UPDATE_SUCCESS";
export const INFORMATIONTECHNOLOGY_UPDATE_FAIL =
  "INFORMATIONTECHNOLOGY_UPDATE_FAIL";
export const INFORMATIONTECHNOLOGY_UPDATE_EMPTY =
  "INFORMATIONTECHNOLOGY_UPDATE_EMPTY";

export const INFORMATIONTECHNOLOGY_DELETE_REQUEST =
  "INFORMATIONTECHNOLOGY_DELETE_REQUEST";
export const INFORMATIONTECHNOLOGY_DELETE_SUCCESS =
  "INFORMATIONTECHNOLOGY_DELETE_SUCCESS";
export const INFORMATIONTECHNOLOGY_DELETE_FAIL =
  "INFORMATIONTECHNOLOGY_DELETE_FAIL";
export const INFORMATIONTECHNOLOGY_DELETE_EMPTY =
  "INFORMATIONTECHNOLOGY_DELETE_EMPTY";
