export const THEFIRM_REQUEST = "THEFIRM_REQUEST";
export const THEFIRM_SUCCESS = "THEFIRM_SUCCESS";
export const THEFIRM_FAIL = "THEFIRM_FAIL";

export const THEFIRM_CREATE_REQUEST = "THEFIRM_CREATE_REQUEST";
export const THEFIRM_CREATE_SUCCESS = "THEFIRM_CREATE_SUCCESS";
export const THEFIRM_CREATE_FAIL = "THEFIRM_CREATE_FAIL";

export const THEFIRM_UPDATE_REQUEST = "THEFIRM_UPDATE_REQUEST";
export const THEFIRM_UPDATE_SUCCESS = "THEFIRM_UPDATE_SUCCESS";
export const THEFIRM_UPDATE_FAIL = "THEFIRM_UPDATE_FAIL";
export const THEFIRM_UPDATE_EMPTY = "THEFIRM_UPDATE_EMPTY";

export const THEFIRM_DELETE_REQUEST = "THEFIRM_DELETE_REQUEST";
export const THEFIRM_DELETE_SUCCESS = "THEFIRM_DELETE_SUCCESS";
export const THEFIRM_DELETE_FAIL = "THEFIRM_DELETE_FAIL";
export const THEFIRM_DELETE_EMPTY = "THEFIRM_DELETE_EMPTY";
