export const INDUSTRYSECTOR_SUCCESS = "INDUSTRYSECTOR_SUCCESS";
export const INDUSTRYSECTOR_FAIL = "INDUSTRYSECTOR_FAIL";
export const INDUSTRYSECTOR_REQUEST = "INDUSTRYSECTOR_REQUEST";
export const INDUSTRYSECTOR_CREATE_REQUEST = "INDUSTRYSECTOR_CREATE_REQUEST";
export const INDUSTRYSECTOR_CREATE_SUCCESS = "INDUSTRYSECTOR_CREATE_SUCCESS";
export const INDUSTRYSECTOR_CREATE_FAIL = "INDUSTRYSECTOR_CREATE_FAIL";
export const INDUSTRYSECTOR_UPDATE_REQUEST = "INDUSTRYSECTOR_UPDATE_REQUEST";
export const INDUSTRYSECTOR_UPDATE_SUCCESS = "INDUSTRYSECTOR_UPDATE_SUCCESS";
export const INDUSTRYSECTOR_UPDATE_FAIL = "INDUSTRYSECTOR_UPDATE_FAIL";
export const INDUSTRYSECTOR_UPDATE_EMPTY = "INDUSTRYSECTOR_UPDATE_EMPTY";
export const INDUSTRYSECTOR_DELETE_REQUEST = "INDUSTRYSECTOR_DELETE_REQUEST";
export const INDUSTRYSECTOR_DELETE_EMPTY = "INDUSTRYSECTOR_DELETE_EMPTY";
export const INDUSTRYSECTOR_DELETE_SUCCESS = "INDUSTRYSECTOR_DELETE_SUCCESS";
export const INDUSTRYSECTOR_DELETE_FAIL = "INDUSTRYSECTOR_DELETE_FAIL";
