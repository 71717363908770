export const SENIOUR_ASSOCIATES_REQUEST = "SENIOUR_ASSOCIATES_REQUEST";
export const SENIOUR_ASSOCIATES_SUCCESS = "SENIOUR_ASSOCIATES_SUCCESS";
export const SENIOUR_ASSOCIATES_FAIL = "SENIOUR_ASSOCIATES_FAIL";

export const SENIOUR_ASSOCIATES_CREATE_REQUEST =
  "SENIOUR_ASSOCIATES_CREATE_REQUEST";
export const SENIOUR_ASSOCIATES_CREATE_SUCCESS =
  "SENIOUR_ASSOCIATES_CREATE_SUCCESS";
export const SENIOUR_ASSOCIATES_CREATE_FAIL = "SENIOUR_ASSOCIATES_CREATE_FAIL";

export const SENIOUR_ASSOCIATES_UPDATE_REQUEST =
  "SENIOUR_ASSOCIATES_UPDATE_REQUEST";
export const SENIOUR_ASSOCIATES_UPDATE_SUCCESS =
  "SENIOUR_ASSOCIATES_UPDATE_SUCCESS";
export const SENIOUR_ASSOCIATES_UPDATE_FAIL = "SENIOUR_ASSOCIATES_UPDATE_FAIL";
export const SENIOUR_ASSOCIATES_UPDATE_EMPTY =
  "SENIOUR_ASSOCIATES_UPDATE_EMPTY";

export const SENIOUR_ASSOCIATES_DELETE_REQUEST =
  "SENIOUR_ASSOCIATES_DELETE_REQUEST";
export const SENIOUR_ASSOCIATES_DELETE_SUCCESS =
  "SENIOUR_ASSOCIATES_DELETE_SUCCESS";
export const SENIOUR_ASSOCIATES_DELETE_FAIL = "SENIOUR_ASSOCIATES_DELETE_FAIL";
export const SENIOUR_ASSOCIATES_DELETE_EMPTY =
  "SENIOUR_ASSOCIATES_DELETE_EMPTY";
