import {
  SENIOUR_ASSOCIATES_CREATE_FAIL,
  SENIOUR_ASSOCIATES_CREATE_REQUEST,
  SENIOUR_ASSOCIATES_CREATE_SUCCESS,
  SENIOUR_ASSOCIATES_DELETE_EMPTY,
  SENIOUR_ASSOCIATES_DELETE_FAIL,
  SENIOUR_ASSOCIATES_DELETE_REQUEST,
  SENIOUR_ASSOCIATES_DELETE_SUCCESS,
  SENIOUR_ASSOCIATES_FAIL,
  SENIOUR_ASSOCIATES_REQUEST,
  SENIOUR_ASSOCIATES_SUCCESS,
  SENIOUR_ASSOCIATES_UPDATE_EMPTY,
  SENIOUR_ASSOCIATES_UPDATE_FAIL,
  SENIOUR_ASSOCIATES_UPDATE_REQUEST,
  SENIOUR_ASSOCIATES_UPDATE_SUCCESS,
} from "../constants/seniourAssociatesConstant";

export const seniourAssociatesReducer = (
  state = { seniourAssociatesAll: [] },
  action
) => {
  switch (action.type) {
    case SENIOUR_ASSOCIATES_REQUEST:
      return { loading: true, seniourAssociatesAll: [] };
    case SENIOUR_ASSOCIATES_SUCCESS:
      return { loading: false, seniourAssociatesAll: action.payload };
    case SENIOUR_ASSOCIATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const seniourAssociatesCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SENIOUR_ASSOCIATES_CREATE_REQUEST:
      return { loading: true };

    case SENIOUR_ASSOCIATES_CREATE_SUCCESS:
      return { loading: false, associates: action.payload };
    case SENIOUR_ASSOCIATES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const seniourAssociatesUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SENIOUR_ASSOCIATES_UPDATE_REQUEST:
      return { loading: true };
    case SENIOUR_ASSOCIATES_UPDATE_EMPTY:
      return { loading: false, seniourAssociatesUpdate: [] };
    case SENIOUR_ASSOCIATES_UPDATE_SUCCESS:
      return { loading: false, seniourAssociatesUpdate: action.payload };
    case SENIOUR_ASSOCIATES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const seniourAssociatesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SENIOUR_ASSOCIATES_DELETE_REQUEST:
      return { delet_loading: true };
    case SENIOUR_ASSOCIATES_DELETE_EMPTY:
      return { delete_loading: false, seniourAssociatesDelete: [] };
    case SENIOUR_ASSOCIATES_DELETE_SUCCESS:
      return { delete_loading: false, seniourAssociatesDelete: action.payload };
    case SENIOUR_ASSOCIATES_DELETE_FAIL:
      return { delete_loading: false, error: action.payload };
    default:
      return state;
  }
};
