import {
  ASSOCIATES_PARTNERS_CREATE_FAIL,
  ASSOCIATES_PARTNERS_CREATE_REQUEST,
  ASSOCIATES_PARTNERS_CREATE_SUCCESS,
  ASSOCIATES_PARTNERS_DELETE_EMPTY,
  ASSOCIATES_PARTNERS_DELETE_FAIL,
  ASSOCIATES_PARTNERS_DELETE_REQUEST,
  ASSOCIATES_PARTNERS_DELETE_SUCCESS,
  ASSOCIATES_PARTNERS_FAIL,
  ASSOCIATES_PARTNERS_REQUEST,
  ASSOCIATES_PARTNERS_SUCCESS,
  ASSOCIATES_PARTNERS_UPDATE_EMPTY,
  ASSOCIATES_PARTNERS_UPDATE_FAIL,
  ASSOCIATES_PARTNERS_UPDATE_REQUEST,
  ASSOCIATES_PARTNERS_UPDATE_SUCCESS,
} from "../constants/associtePartnersConstant";

export const associatesPartnersReducer = (
  state = { associatesPartnersAll: [] },
  action
) => {
  switch (action.type) {
    case ASSOCIATES_PARTNERS_REQUEST:
      return { loading: true, associatesPartnersAll: [] };
    case ASSOCIATES_PARTNERS_SUCCESS:
      return { loading: false, associatesPartnersAll: action.payload };
    case ASSOCIATES_PARTNERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const associatesPartnersCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSOCIATES_PARTNERS_CREATE_REQUEST:
      return { loading: true };

    case ASSOCIATES_PARTNERS_CREATE_SUCCESS:
      return { loading: false, associatesPartners: action.payload };
    case ASSOCIATES_PARTNERS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const associatesPartnersUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSOCIATES_PARTNERS_UPDATE_REQUEST:
      return { loading: true };
    case ASSOCIATES_PARTNERS_UPDATE_EMPTY:
      return { loading: false, associatesPartnersUpdate: [] };
    case ASSOCIATES_PARTNERS_UPDATE_SUCCESS:
      return { loading: false, associatesPartnersUpdate: action.payload };
    case ASSOCIATES_PARTNERS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const associatesPartnersDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSOCIATES_PARTNERS_DELETE_REQUEST:
      return { delet_loading: true };
    case ASSOCIATES_PARTNERS_DELETE_EMPTY:
      return { delete_loading: false, associatesPartnersDelete: [] };
    case ASSOCIATES_PARTNERS_DELETE_SUCCESS:
      return {
        delete_loading: false,
        associatesPartnersDelete: action.payload,
      };
    case ASSOCIATES_PARTNERS_DELETE_FAIL:
      return { delete_loading: false, error: action.payload };
    default:
      return state;
  }
};
