import { HashLink as Link } from "react-router-hash-link";

const Brand = (props) => {
  return (
    <div className={props.style}>
      <Link to={props.visitLink}>
        <h1>singhal</h1>
        <h1>consultancy</h1>
      </Link>
    </div>
  );
};

export default Brand;
