export const ASSOCIATES_PARTNERS_REQUEST = "ASSOCIATES_PARTNERS_REQUEST";
export const ASSOCIATES_PARTNERS_SUCCESS = "ASSOCIATES_PARTNERS_SUCCESS";
export const ASSOCIATES_PARTNERS_FAIL = "ASSOCIATES_PARTNERS_FAIL";

export const ASSOCIATES_PARTNERS_CREATE_REQUEST =
  "ASSOCIATES_PARTNERS_CREATE_REQUEST";
export const ASSOCIATES_PARTNERS_CREATE_SUCCESS =
  "ASSOCIATES_PARTNERS_CREATE_SUCCESS";
export const ASSOCIATES_PARTNERS_CREATE_FAIL =
  "ASSOCIATES_PARTNERS_CREATE_FAIL";

export const ASSOCIATES_PARTNERS_UPDATE_REQUEST =
  "ASSOCIATES_PARTNERS_UPDATE_REQUEST";
export const ASSOCIATES_PARTNERS_UPDATE_SUCCESS =
  "ASSOCIATES_PARTNERS_UPDATE_SUCCESS";
export const ASSOCIATES_PARTNERS_UPDATE_FAIL =
  "ASSOCIATES_PARTNERS_UPDATE_FAIL";
export const ASSOCIATES_PARTNERS_UPDATE_EMPTY =
  "ASSOCIATES_PARTNERS_UPDATE_EMPTY";

export const ASSOCIATES_PARTNERS_DELETE_REQUEST =
  "ASSOCIATES_PARTNERS_DELETE_REQUEST";
export const ASSOCIATES_PARTNERS_DELETE_SUCCESS =
  "ASSOCIATES_PARTNERS_DELETE_SUCCESS";
export const ASSOCIATES_PARTNERS_DELETE_FAIL =
  "ASSOCIATES_PARTNERS_DELETE_FAIL";
export const ASSOCIATES_PARTNERS_DELETE_EMPTY = "ASSOCIATES_DELETE_EMPTY";
