export const PARTNERS_REQUEST = "PARTNERS_REQUEST";
export const PARTNERS_SUCCESS = "PARTNERS_SUCCESS";
export const PARTNERS_FAIL = "PARTNERS_FAIL";

export const PARTNERS_CREATE_REQUEST = "PARTNERS_CREATE_REQUEST";
export const PARTNERS_CREATE_SUCCESS = "PARTNERS_CREATE_SUCCESS";
export const PARTNERS_CREATE_FAIL = "PARTNERS_CREATE_FAIL";

export const PARTNERS_UPDATE_REQUEST = "PARTNERS_UPDATE_REQUEST";
export const PARTNERS_UPDATE_SUCCESS = "PARTNERS_UPDATE_SUCCESS";
export const PARTNERS_UPDATE_FAIL = "PARTNERS_UPDATE_FAIL";
export const PARTNERS_UPDATE_EMPTY = "PARTNERS_UPDATE_EMPTY";

export const PARTNERS_DELETE_REQUEST = "PARTNERS_DELETE_REQUEST";
export const PARTNERS_DELETE_SUCCESS = "PARTNERS_DELETE_SUCCESS";
export const PARTNERS_DELETE_FAIL = "PARTNERS_DELETE_FAIL";
export const PARTNERS_DELETE_EMPTY = "PARTNERS_DELETE_EMPTY";
